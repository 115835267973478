"use client";
import Link from "next/link";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import ThemeChanger from "./DarkSwitch";

export const Navbar = () => {
  const navigation = [
    {
      id: 1,
      title: "Benefit",
      link: "#benefit",
    },
    {
      id: 2,
      title: "Testimonials",
      link: "#testimonials",
    },
    {
      id: 3,
      title: "FAQ",
      link: "#faq",
    },
  ];

  return (
    <div className="w-full">
      <nav className="container relative flex flex-wrap items-center justify-between p-8 mx-auto lg:justify-between xl:px-0">
        {/* Logo  */}
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
              <Link href="/">
                <span className="flex items-center space-x-2 text-2xl font-medium">
                  <span className="bg-gradient-to-r inline-block text-transparent bg-clip-text from-green-600 to-biruMuda-400 dark:from-biruMuda-600 dark:to-biruTua-400">
                    Sahabad Solution
                  </span>
                </span>
              </Link>

              <DisclosureButton
                aria-label="Toggle Menu"
                className="px-2 py-1 ml-auto text-gray-500 rounded-md lg:hidden hover:text-biruMuda-500 focus:text-biruMuda-500 focus:bg-biruMuda-100 focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700"
              >
                <svg
                  className="w-6 h-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  {open && (
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                    />
                  )}
                  {!open && (
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    />
                  )}
                </svg>
              </DisclosureButton>

              <DisclosurePanel className="flex flex-wrap w-full my-5 lg:hidden">
                <>
                  {navigation.map((item, index) => (
                    <Link
                      key={item.id}
                      href={item.link || "/"}
                      className="w-full px-4 py-2 -ml-4 text-gray-500 rounded-md dark:text-gray-300 hover:text-biruMuda-500 focus:text-biruMuda-500 focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none"
                    >
                      {item.title}
                    </Link>
                  ))}
                  <Link
                    href="/"
                    className="w-full px-6 py-2 mt-3 text-center text-white bg-biruTua-500 rounded-md lg:ml-5"
                  >
                    Get Started
                  </Link>
                </>
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>

        {/* menu  */}
        <div className="hidden text-center lg:flex lg:items-center">
          <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
            {navigation.map((menu, index) => (
              <li className="mr-3 nav__item" key={menu.id}>
                <Link
                  href={menu.link}
                  className="inline-block px-4 py-2 text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-biruMuda-500 hover:text-xl focus:text-biruMuda-500 focus:bg-indigo-100 focus:outline-none dark:hover:text-biruMuda-500 dark:focus:bg-gray-800"
                  replace
                >
                  {menu.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden mr-3 space-x-4 lg:flex nav__item">
          <Link
            href="/"
            className="px-6 py-2 text-white bg-biruTua-500 rounded-md md:ml-5 dark:bg-biruTua-600"
          >
            Get Started
          </Link>

          <ThemeChanger />
        </div>
      </nav>
    </div>
  );
};
